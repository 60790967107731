<template>
  <button class="telegram-bot-button" @click="handleClick">
    <img src="@/assets/telegram.logo.circle.svg" alt="Telegram Logo" class="telegram-logo" />
    <span class="telegram-button-title">{{ title }}</span>
  </button>
</template>

<script>
export default {
  name: 'TelegramBotButton',
  props: {
    title: {
      type: String,
      required: true,
    },
  },
  methods: {
    handleClick(event) {
      this.$emit('click', event);
    },
  },
};
</script>

<style scoped>
.telegram-bot-button {
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  background-color: white;
  border: 1px solid #cccccc;
  border-radius: 40px;
  padding: 10px;
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
  box-sizing: border-box;
}

.telegram-logo {
  width: 26px;
  height: 26px;
  margin-right: 10px;
}

.telegram-button-title {
  font-size: 16px;
  font-weight: 600;
  color: black;
  white-space: nowrap;
}
</style>
