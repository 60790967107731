<template>
  <footer class="footer">
    <div class="footer-left">
      <img src="@/assets/wisher.logo.gray.svg" class="footer-logo" />
      <nav class="footer-links">
        <router-link to="/contact" class="footer-link">{{ $t('footer.contactsLinkTitle') }}</router-link>
        <router-link to="/privacy" class="footer-link">{{ $t('footer.privacyPolicyLinkName') }}</router-link>
        <router-link to="/terms" class="footer-link">{{ $t('footer.userAgreementLinkName') }}</router-link>
      </nav>
    </div>
    <div class="footer-right">
      <a href="https://x.com/wisherpro" target="_blank" class="social-icon" @click="handleXClick">
        <img src="@/assets/x-logo.svg" />
      </a>
      <a href="https://instagram.com/wisher.pro" target="_blank" class="social-icon" @click="handleInstagramClick">
        <img src="@/assets/instagram-logo.svg" />
      </a>
    </div>
  </footer>
</template>

<script>
import { analytics, logEvent } from '../main';

export default {
  name: 'AppFooter',
  methods: {
    handleXClick() {
      logEvent(analytics, 'x_button_click');
    },
    handleInstagramClick() {
      logEvent(analytics, 'instagram_button_click');
    }
  }
};
</script>

<style scoped>
.footer {
  background-color: #F2F2F2;
  color: #616161;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
}

.footer-left {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
}

.footer-logo {
  height: 24px;
  margin-right: 20px;
  margin-left: 16px;
}

.footer-links {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  margin-left: 16px;
  flex-grow: 1;
  margin-top: 0;
}

.footer-link {
  color: #616161;
  text-decoration: none;
  font-size: 16px;
  font-family: 'Source Code Pro', monospace;
}

.footer-link:hover {
  text-decoration: underline;
}

.footer-right {
  display: flex;
  align-items: center;
  gap: 30px;
}

.social-icon img {
  width: 20px;
  height: 20px;
}

@media (max-width: 767px) {
  .footer {
    flex-direction: column;
    align-items: flex-start;
  }

  .footer-left {
    order: 1;
    width: 100%;
  }

  .footer-links {
    margin-left: 0;
    padding-left: 16px;
    margin-top: 20px;
  }

  .footer-right {
    order: 2;
    width: 100%;
    justify-content: flex-start;
    margin-top: 20px;
    padding-left: 16px;
  }
}

@media (min-width: 768px) {
  .footer-left {
    width: auto;
  }

  .footer-right {
    order: 0;
    width: auto;
    justify-content: flex-end;
  }

  .footer-link {
    font-size: 14px;
  }
}
</style>
