<template>
    <div class="about-page">
    <link rel="icon" href="/favicon.ico">
    </div>
  </template>
  
  <script>
  export default {
    name: 'AboutPage',
  };
  </script>
  
  <style scoped>
  </style>
  