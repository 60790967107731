<template>
    <div class="stack-container">
      <TelegramBotButton
        :title="$t('main.telegramButtonTitle')"
        @click="handleTelegramClick"
      />
      <OrDivider />
      <AppStoreButton />
    </div>
  </template>
  
  <script>
  import TelegramBotButton from './TelegramBotButton.vue';
  import OrDivider from './OrDivider.vue';
  import AppStoreButton from './AppStoreButton.vue';
  import { analytics, logEvent } from '../main';
  
  export default {
    name: 'StackLayout',
    components: {
      TelegramBotButton,
      OrDivider,
      AppStoreButton,
    },
    methods: {
      handleTelegramClick() {
        window.open('https://t.me/wisherprobot', '_blank');
        logEvent(analytics, 'telegram_bot_click');
      },
    },
  };
  </script>
  
  <style scoped>
  .stack-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    gap: 40px;
  }
  
  @media (max-width: 767px) {
    .stack-container {
      flex-direction: column;
      gap: 20px;
    }
  }
  </style>
  