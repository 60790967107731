<template>
  <link rel="icon" href="/favicon.ico">
  <div class="privacy-page">
    <div v-if="error">
      <p>Error loading Privacy Policy. Please try again later.</p>
    </div>
    <div v-else>
      <div v-for="(section, index) in sections" :key="index" class="privacy-item">
        <h2 class="privacy-title">{{ section.title }}</h2>
        <p class="privacy-text" v-html="section.text"></p>
      </div>
    </div>
  </div>
</template>

<script>
import privacyData from '@/assets/docs/privacy/en.json';

export default {
  name: 'PrivacyPage',
  data() {
    return {
      sections: [],
      error: false,
    };
  },
  mounted() {
    this.fetchPrivacySections();
  },
  methods: {
    fetchPrivacySections() {
      try {
        this.sections = privacyData;
        this.error = false;
      } catch (error) {
        console.error('Error fetching Privacy Policy:', error);
        this.error = true;
        this.sections = [];
      }
    },
  },
};
</script>

<style scoped>
.privacy-page {
  padding: 20px;
  max-width: 100%;
  margin: 0 auto;
}

.privacy-item {
  margin-bottom: 10px;
}

.privacy-title {
  font-size: 20px;
  display: inline-block;
  margin-bottom: 4px;
}

.privacy-text {
  font-size: 18px;
}

@media (min-width: 1024px) {
  .privacy-page {
    max-width: 50%;
  }
}
</style>
