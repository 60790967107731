<template>
    <button class="promo-snack-button" @click="handleClick">
        <img :src="resolvedIcon" alt="icon" class="icon" />
        <span class="text">{{ title }}</span>
    </button>
  </template>
  
  <script>
  export default {
    name: 'PromoSnackButton',
    props: {
      title: {
        type: String,
        required: true,
      },
      icon: {
        type: String,
        required: true
      }
    },
    computed: {
      resolvedIcon() {
        return require(`@/assets/${this.icon}`);
      }
    },
    methods: {
      handleClick(event) {
        this.$emit('click', event);
      },
    },
  };
  </script>
  
<style scoped>
.promo-snack-button {
    display: inline-flex;
    align-items: center;
    background-color: white;
    box-shadow: 0 4px 14px rgba(0, 0, 0, 0.2);
    padding: 14px 18px;
    border-radius: 28px;
    border: none;
    outline: none;
    cursor: pointer;
  
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.promo-snack-button:hover {
    box-shadow: 0 6px 18px rgba(0, 0, 0, 0.4);
}

.promo-snack-button:focus {
    outline: none;
}

.icon {
    width: 24px;
    height: 24px;
    margin-right: 12px;
}

.text {
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 16px;
    color: #000000;
}

</style>
  