<template>
    <div class="clickable-tag-view" @click="handleClick">
      <span class="text">{{ text }}</span>
      <img :src="resolvedIcon" alt="icon" class="icon" />
    </div>
  </template>
  
  <script>
  export default {
    name: 'ClickableTagView',
    props: {
      text: {
        type: String,
        required: true
      },
      icon: {
        type: String,
        required: true
      }
    },
    computed: {
      resolvedIcon() {
        return require(`@/assets/${this.icon}`);
      }
    },
    methods: {
      handleClick() {
        this.$emit('click');
      }
    }
  }
  </script>
  
  <style scoped>
  .clickable-tag-view {
    display: inline-flex;
    align-items: center;
    background-color: white;
    border: 1px solid #EEEFF1;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.05);
    padding: 4px 8px;
    border-radius: 8px;
    cursor: pointer;
    transition: transform 0.1s ease;
  }
  
  .clickable-tag-view:active {
    transform: scale(0.98);
  }
  
  .icon {
    width: 15px;
    height: 15px;
    margin-left: 6px;
  }
  
  .text {
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 12px;
    color: #316FFF;
  }
  </style>
  