<template>
  <button class="new-year-promo-button" @click="handleClick">
    <span class="promo-button-title">{{ title }}</span>
  </button>
</template>

<script>
export default {
  name: 'NewYearPromoButton',
  props: {
    title: {
      type: String,
      required: true,
    },
  },
  methods: {
    handleClick(event) {
      this.$emit('click', event);
    },
  },
};
</script>

<style scoped>
.new-year-promo-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(to right, #E33C98, #4DCF4D);
  padding: 10px 20px;
  box-sizing: border-box;
  position: relative;
  border: none;
  border-radius: 20px;
  overflow: hidden;
  color: white;
  font-size: 16px;
  font-weight: 600;
  background-clip: padding-box;
}

.new-year-promo-button::before {
  content: '';
  position: absolute;
  inset: 0;
  background: white;
  border-radius: 20px;
  margin: 3px;
}
.new-year-promo-button .promo-button-title {
  position: relative;
  z-index: 1;
  font-size: 16px;
  font-weight: 600;
  background: linear-gradient(to right, #E33C98, #4DCF4D);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
</style>
