<template>
  <button class="appstore-button" @click="handleClick">
    <img :src="appStoreLogo" :alt="`App Store Button (${currentLocale})`" class="appstore-logo" />
  </button>
</template>

<script>
import { analytics, logEvent } from '../main';

export default {
  name: 'AppStoreButton',
  computed: {
    currentLocale() {
      return this.$i18n.locale;
    },
    appStoreLogo() {
      return require(`@/assets/AppStoreLogos/appstore.button.logo.${this.currentLocale}.svg`);
    }
  },
  methods: {
    handleClick() {
      window.open('https://apps.apple.com/app/wisher-pro/id6468797180/', '_blank');
      logEvent(analytics, 'appstore_button_click');
    },
  },
};
</script>

<style scoped>
.appstore-button {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
}

.appstore-logo {
  width: 150px;
  height: auto;
}
</style>
