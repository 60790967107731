<template>
  <link rel="icon" href="/favicon.ico">
  <div class="main-container">
    <NewYearPromoButton
        :title="$t('main.newYearPromoButtonTitle')"
        @click="handleNewYearPromoClick"
        class="ny-promo-button"
      />
    <div class="title">{{ $t('main.title') }}</div>
    <div class="subtitle">{{ $t('main.subtitle') }}</div>
    <StackLayout />
    <img :src="phonesImage" class="responsive-image" />
    <DemoWishlistView />
    <div class="additional-content">
      <h2 class="content-title">{{ $t('main.socialTitle') }}</h2>
      <img :src="socialImage" class="content-image" />
    </div>
  </div>
</template>

<script>
import StackLayout from './StackLayout.vue';
import DemoWishlistView from './DemoWishlistView.vue';
import NewYearPromoButton from './NewYearPromoButton.vue';
import { useHead } from '@vueuse/head';

export default {
  name: 'MainPage',
  computed: {
    currentLocale() {
      return this.$i18n.locale;
    },
    phonesImage() {
      return require(`@/assets/main_phones.${this.currentLocale}.png`);
    },
    socialImage() {
      return require(`@/assets/social-${this.isMobile ? 'mobile' : 'desktop'}.png`);
    },
    isMobile() {
      return window.innerWidth <= 767;
    },
    domain() {
      return window.location.hostname;
    }
  },
  components: {
    StackLayout,
    DemoWishlistView,
    NewYearPromoButton,
  },
  methods: {
  handleNewYearPromoClick() {
  },
},
  setup() {
    const domain = window.location.hostname;
    const commonHeadConfig = {
      link: [
        { rel: 'icon', href: '/favicon.ico' },
      ],
    };
    if (domain === 'wisher-test.pro') {
      useHead({
        ...commonHeadConfig,
        meta: [
          { name: 'robots', content: 'noindex, nofollow' },
        ],
      });
    } else if (domain === 'wisher.pro') {
      useHead({
        ...commonHeadConfig,
        title: "Wisher Pro - персональный вишлист",
        meta: [
          { name: 'description', content: "Создайте в приложении или боте - опубликуйте где угодно" },
          { property: 'og:title', content: "Wisher Pro" },
          { property: 'og:description', content: "Создайте в приложении или боте - опубликуйте где угодно" },
          { property: 'og:url', content: 'https://wisher.pro' },
          { property: 'og:type', content: 'website' },
          { name: 'twitter:card', content: 'summary_large_image' },
          { name: 'application-name', content: 'Wisher Pro' },
          { property: 'og:image', content: 'https://wisher.pro/favicon.ico' }
        ],
      });
    }
  },
};
</script>

<style scoped>
.main-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 16px;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
}

.ny-promo-button {
  margin-top: 40px;
  margin-bottom: 30px;
}

.title {
  font-size: 60px;
  font-weight: 600;
  margin-bottom: 20px;
  text-align: center;
  margin-left: 10px;
  margin-right: 10px;
  color: #1B202A;
}

.subtitle {
  font-size: 26px;
  font-weight: 500;
  margin-bottom: 40px;
  text-align: center;
  margin-left: 10px;
  margin-right: 10px;
  white-space: pre-wrap; 
  word-wrap: break-word;
  color: #1B202A;
}

.responsive-image {
  width: 70%;
  max-width: 100%;
  height: auto;
  margin-top: 120px;
  margin-bottom: 200px;
}

.additional-content {
  text-align: center;
  margin-top: 0px;
  margin-bottom: 40px;
}

.content-title {
  text-align: center;
  font-size: 36px;
  font-weight: 600;
  margin-bottom: 80px;
  margin-top: 0px;
  margin-left: 10px;
  margin-right: 10px;
  color: #1B202A;
}

.content-image {
  width: 90%;
  max-width: 100%;
  height: auto;
}

@media (max-width: 767px) {
  .title {
  font-size: 52px;
  font-weight: 600;
  margin-bottom: 20px;
  text-align: left;
  margin-left: 20px;
  margin-right: 20px;
  color: #1B202A;
}

.subtitle {
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 40px;
  text-align: left;
  margin-left: 20px;
  margin-right: 20px;
  white-space: pre-wrap; 
  word-wrap: break-word;
  width: calc(100% - 40px);
  box-sizing: border-box;
  color: #1B202A;
}

  .responsive-image {
    width: 80%;
    margin-bottom: 100px;
  }

  .content-title {
    font-size: 30px;
    color: #1B202A;
  }

  .content-image {
    width: 80%;
  }
}
</style>

<style>
body {
  background-color: #FAFAFA;
  margin: 0;
}
</style>
