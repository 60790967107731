<template>
    <div class="tag-view">
      <img :src="resolvedIcon" alt="icon" class="icon" />
      <span class="text">{{ text }}</span>
    </div>
  </template>
  
  <script>
  export default {
    name: 'TagView',
    props: {
      text: {
        type: String,
        required: true
      },
      icon: {
        type: String,
        required: true
      }
    },
    computed: {
      resolvedIcon() {
        return require(`@/assets/${this.icon}`);
      }
    }
  }
  </script>
  
  <style scoped>
  .tag-view {
    display: inline-flex;
    align-items: center;
    background-color: white;
    border: 1px solid #EEEFF1;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.05);
    padding: 4px 8px;
    border-radius: 8px;
  }
  
  .icon {
    width: 15px;
    height: 15px;
    margin-right: 6px;
  }
  
  .text {
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 12px;
    color: #394150;
  }
  </style>
  