<template>
  <link rel="icon" href="/favicon.ico">
  <div class="agreement-page">
    <div v-if="error">
      <p>Error loading Agreement. Please try again later.</p>
    </div>
    <div v-else>
      <div v-for="(section, index) in sections" :key="index" class="agreement-item">
        <h2 class="agreement-title">{{ section.title }}</h2>
        <p class="agreement-text" v-html="section.text"></p>
      </div>
    </div>
  </div>
</template>

<script>
import agreementData from '@/assets/docs/terms/en.json';

export default {
  name: 'AgreementPage',
  data() {
    return {
      sections: [],
      error: false,
    };
  },
  mounted() {
    this.fetchAgreementSections();
  },
  methods: {
    fetchAgreementSections() {
      try {
        this.sections = agreementData;
        this.error = false;
      } catch (error) {
        console.error('Error fetching Agreement:', error);
        this.error = true;
        this.sections = [];
      }
    },
  },
};
</script>

<style scoped>
.agreement-page {
  padding: 20px;
  max-width: 100%;
  margin: 0 auto;
}

.agreement-item {
  margin-bottom: 10px;
}

.agreement-title {
  font-size: 20px;
  display: inline-block;
  margin-bottom: 4px;
}

.agreement-text {
  font-size: 18px;
}

@media (min-width: 1024px) {
  .agreement-page {
    max-width: 50%;
  }
}
</style>
