<template>
  <link rel="icon" href="/favicon.ico">
  <div class="contact-page">
    <div class="content-section">
      <h1>{{ $t('contacts.title') }}</h1>
      <div class="subtitle">{{ $t('contacts.subtitle') }}</div>
      <div class="contact-info">
        <div v-for="(item, index) in contactItems" :key="index" class="contact-item">
          <div class="contact-text">{{ $t(item.title) }}</div>
          <div class="contact-label">{{ $t(item.subtitle) }}</div>
          <div class="contact-link">{{ $t(item.linkText) }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ContactPage',
  data() {
    return {
      contactItems: [
        { title: 'contacts.supportEmailTitle', subtitle: 'contacts.supportEmailSubtitle', linkText: 'support@wisher.pro' },
        { title: 'contacts.prEmailTitle', subtitle: 'contacts.prEmailSubtitle', linkText: 'pr@wisher.pro' },
        { title: 'contacts.defaultEmailTitle', subtitle: 'contacts.defaultEmailSubtitle', linkText: 'info@wisher.pro' }
      ]
    };
  }
};
</script>

<style scoped>
.contact-page {
  font-family: Arial, sans-serif;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  min-height: 100vh;
}

.content-section {
  text-align: left;
  width: 40%;
  max-width: 1200px;
}

h1 {
  font-size: 2em;
  margin-top: 40px;
  margin-bottom: 20px;
}

.subtitle {
  font-size: 17px;
  margin-bottom: 30px;
}

.contact-info {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.contact-item {
  display: flex;
  flex-direction: column;
}

.contact-text {
  margin-top: 14px;
  margin-bottom: 5px;
  font-weight: bold;
}

.contact-label {
  margin-top: 5px;
  margin-bottom: 7px;
}

.contact-link {
  font-size: 15px;
  color: #6D441C;
  background-color: #FEFCC6;
  font-family: 'Source Code Pro', monospace;
  padding: 4px 0px;
  display: inline-block;
  width: max-content;
  box-sizing: border-box;
  border-radius: 4px;
}

@media (max-width: 768px) {
  .contact-page {
    display: block;
    padding: 20px;
    text-align: left;
    align-items: flex-start;
  }

  .content-section {
    text-align: left;
    width: auto;
    max-width: none;
  }

  .contact-info {
    gap: 15px;
  }
}
</style>
