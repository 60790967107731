<template>
  <div class="user-info">
    <img :src="photoUrl" alt="User Photo" class="user-photo" @error="setDefaultPhoto" />
    <div class="user-details">
      <h1 class="user-name">{{ user.name }}</h1>
      <p class="user-short-id">
        wisher.pro/
        <span class="short-id">{{ user.short_id }}</span>
      </p>
      <p class="user-description">{{ user.description }}</p>
      <p class="user-birthday" v-if="formattedBirthday">
        🎂 {{ formattedBirthday }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UserInfoHeader',
  props: {
    user: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      photoUrl: ''
    };
  },
  computed: {
    formattedBirthday() {
      if (!this.user.birthday) return '';
      const date = new Date(this.user.birthday);
      const day = String(date.getDate()).padStart(2, '0');
      const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
      const monthWord = months[date.getMonth()];
      return `${day} ${monthWord}`;
    }
  },
  mounted() {
    this.loadUserPhoto();
  },
  methods: {
    loadUserPhoto() {
      const photoUrl = `${process.env.VUE_APP_API_BASE_URL}/rest/users/${this.user.id}/photo`;
      this.photoUrl = photoUrl;
    },
    setDefaultPhoto() {
      this.photoUrl = require('@/assets/avatar-default.png');
    }
  }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Source+Code+Pro:wght@400&display=swap');

.user-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 16px;
}

.user-photo {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-bottom: 16px;
  object-fit: cover;
}

.user-details {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.user-name {
  font-size: 24px;
  font-weight: 500;
  margin: 0;
  margin-bottom: 8px;
  font-family: 'Roboto', sans-serif;
}

.user-short-id {
  font-size: 16px;
  margin: 0 0 0 0;
  display: flex;
  align-items: center;
  font-family: 'Source Code Pro', monospace;
}

.short-id {
  font-size: 13px;
  background-color: #CF7135;
  color: white;
  border-radius: 6px;
  padding: 1px 8px;
  margin-left: 4px;
  font-family: 'Source Code Pro', monospace;
}

.user-description {
  margin-top: 8px;
  margin-left: 16px;
  margin-right: 16px;
  max-width: 400px;
  box-sizing: border-box;
  text-align: center;
}

.user-birthday {
  font-size: 13px;
  margin-top: 2px;
}

@media (max-width: 767px) {
  .user-description {
    margin-top: 8px;
    margin-left: 16px;
    margin-right: 16px;
    max-width: calc(100% - 32px);
    box-sizing: border-box;
    text-align: center;
  }
}

</style>