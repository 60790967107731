<template>
    <button class="empty-state-promo-view">
      <span class="promo-button-title">{{ title }}</span>
    </button>
  </template>
  
  <script>
  export default {
    name: 'EmptyStatePromoView',
    props: {
      title: {
        type: String,
        required: true,
      },
    },
  };
  </script>
  
  <style scoped>
  .empty-state-promo-view {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(to right, #5B5B5B, #AAAAAA);
    padding: 10px 20px;
    box-sizing: border-box;
    position: relative;
    border: none;
    border-radius: 20px;
    overflow: hidden;
    color: white;
    font-size: 16px;
    font-weight: 600;
    background-clip: padding-box;
  }
  
  .empty-state-promo-view::before {
    content: '';
    position: absolute;
    inset: 0;
    background: white;
    border-radius: 20px;
    margin: 3px;
  }
  .empty-state-promo-view .promo-button-title {
    position: relative;
    z-index: 1;
    font-size: 16px;
    font-weight: 600;
    background: #5E5E5E;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  </style>
  