import { createRouter, createWebHistory } from 'vue-router';
import MainPage from '../components/MainPage.vue';
import UserInfo from '../components/UserInfo.vue';
import About from '../components/AboutPage.vue';
import Contact from '../components/ContactPage.vue';
import PrivacyPolicy from '../components/PrivacyPage.vue';
import UserAgreement from '../components/AgreementPage.vue';
import { analytics, logEvent } from '../main';

const routes = [
    {
        path: '/',
        name: 'Welcome',
        component: MainPage,
        meta: {
            title: 'Wisher Pro'
        }
    },
    {
        path: '/about',
        name: 'AboutPage',
        component: About,
        meta: {
            title: 'Wisher Pro'
        }
    },
    {
        path: '/contact',
        name: 'ContactPage',
        component: Contact,
        meta: {
            title: 'Wisher Pro'
        }
    },
    {
        path: '/privacy',
        name: 'PrivacyPage',
        component: PrivacyPolicy,
        meta: {
            title: 'Wisher Pro'
        }
    },
    {
        path: '/terms',
        name: 'AgreementPage',
        component: UserAgreement,
        meta: {
            title: 'Wisher Pro'
        }
    },
    {
        path: '/:short_id',
        name: 'Wishlist',
        component: UserInfo,
        meta: {
            title: 'Wisher Pro'
        }
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes
});

router.beforeEach((to, from, next) => {
    document.title = to.meta.title || 'Default Title';
    next();
});

router.afterEach((to) => {
    logEvent(analytics, 'page_view', {
        page_name: to.name
    });
});

export default router;
