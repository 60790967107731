<template>
    <div class="or-divider">
      <div class="bar"></div>
      <div class="or-label">{{ $t('main.dividerTitle') }}</div>
      <div class="bar"></div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'OrDivider',
  };
  </script>
  
  <style scoped>
  .or-divider {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 16px 0;
    flex-direction: column;
  }
  
  .bar {
    width: 2px;
    height: 16px;
    background-color: #E0E0E0;
    margin: 3px 0;
  }
  
  .or-label {
    font-size: 12px;
    font-weight: 600;
    margin: 6px 0;
    color: #4B4B4B;
  }
  
  @media (max-width: 767px) {
    .or-divider {
      flex-direction: row;
    }
  
    .bar {
      width: 16px;
      height: 2px;
      margin: 0 5px;
    }
  
    .or-label {
      margin: 0 5px;
    }
  }
  </style>
  